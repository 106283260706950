<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 672 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_908_1247)">
      <path d="M0 59.641V46.7588H12.5352V59.641H0Z" fill="currentColor" />
      <path
        d="M39.2104 60.2471C30.5355 60.2471 26.2414 55.3024 26.2414 47.0613V25.2873H18.3906V16.7859H26.2414V0L36.5645 0.0867489V16.7425H47.3647V25.2439H36.5645V46.107C36.5645 49.4035 38.0392 50.7915 41.3357 50.7915C43.461 50.7915 45.196 50.7047 48.0154 50.4011V59.076C45.1093 59.8133 42.2466 60.2471 39.167 60.2471H39.2104Z"
        fill="currentColor"
      />
      <path
        d="M80.197 59.642V33.1402C80.197 28.1955 78.5488 25.1593 73.0836 25.1593C66.8811 25.1593 64.2352 31.7522 64.2352 37.0439V59.642H53.9121V0.0888672H64.2352V21.299C66.7076 17.6555 71.4788 15.7037 75.9463 15.7037C85.0983 15.7037 90.4767 22.2098 90.4767 31.0582V59.6853H80.1536L80.197 59.642Z"
        fill="currentColor"
      />
      <path
        d="M136.108 41.4235H107.395C107.308 44.72 108.479 47.8429 110.518 49.8382C111.992 51.2261 114.074 52.484 116.85 52.484C119.8 52.484 121.621 51.9201 123.139 50.402C124.05 49.4912 124.788 48.32 125.221 46.7586H135.111C134.851 49.3177 133.116 52.7009 131.467 54.6961C127.824 58.9902 122.489 60.7251 117.024 60.7251C111.168 60.7251 106.874 58.6432 103.491 55.4335C99.1968 51.3129 96.8979 45.2838 96.8979 38.2572C96.8979 31.2305 98.9799 25.2882 103.1 21.0809C106.31 17.7844 110.951 15.6157 116.807 15.6157C123.139 15.6157 128.952 18.1748 132.465 23.5532C135.588 28.2377 136.325 33.139 136.195 38.474C136.195 38.474 136.108 40.4693 136.108 41.3801V41.4235ZM123.226 26.416C121.838 24.9412 119.67 23.9436 116.72 23.9436C113.597 23.9436 111.038 25.2448 109.563 27.2401C108.089 29.1485 107.438 31.1004 107.351 33.9197H125.915C125.829 30.71 124.918 28.2377 123.27 26.416H123.226Z"
        fill="currentColor"
      />
      <path
        d="M174.409 60.2471C165.734 60.2471 161.44 55.3024 161.44 47.0613V25.2873H153.589V16.7859H161.44V0L171.763 0.0867489V16.7425H182.563V25.2439H171.763V46.107C171.763 49.4035 173.238 50.7915 176.534 50.7915C178.66 50.7915 180.395 50.7047 183.214 50.4011V59.076C180.308 59.8133 177.445 60.2471 174.366 60.2471H174.409Z"
        fill="currentColor"
      />
      <path
        d="M225.146 41.4235H196.432C196.345 44.72 197.517 47.8429 199.555 49.8382C201.03 51.2261 203.112 52.484 205.888 52.484C208.837 52.484 210.659 51.9201 212.177 50.402C213.088 49.4912 213.825 48.32 214.259 46.7586H224.148C223.888 49.3177 222.153 52.7009 220.505 54.6961C216.862 58.9902 211.526 60.7251 206.061 60.7251C200.206 60.7251 195.912 58.6432 192.528 55.4335C188.234 51.3129 185.936 45.2838 185.936 38.2572C185.936 31.2305 188.018 25.2882 192.138 21.0809C195.348 17.7844 199.989 15.6157 205.844 15.6157C212.177 15.6157 217.989 18.1748 221.503 23.5532C224.626 28.2377 225.363 33.139 225.233 38.474C225.233 38.474 225.146 40.4693 225.146 41.3801V41.4235ZM212.264 26.416C210.876 24.9412 208.707 23.9436 205.758 23.9436C202.635 23.9436 200.076 25.2448 198.601 27.2401C197.126 29.1485 196.476 31.1004 196.389 33.9197H214.953C214.866 30.71 213.955 28.2377 212.307 26.416H212.264Z"
        fill="currentColor"
      />
      <path
        d="M263.885 53.7825C260.936 57.5127 255.731 60.5489 249.181 60.5489C242.632 60.5489 237.21 57.4259 234.261 53.7825C231.051 49.7486 229.576 44.3702 229.576 38.1243C229.576 31.8784 231.051 26.4999 234.261 22.4661C237.21 18.8227 242.675 15.6997 249.181 15.6997C255.688 15.6997 260.893 18.7359 263.885 22.4661C265.881 25.0252 267.008 28.4084 267.182 31.7916H257.032C256.945 30.2301 256.468 28.8855 255.774 27.8445C254.516 25.9361 252.304 24.6348 249.181 24.6348C246.362 24.6348 244.323 25.806 242.892 27.2807C240.42 30.0133 239.986 34.1338 239.986 38.1677C239.986 42.2015 240.42 46.2353 242.892 48.9679C244.28 50.4426 246.362 51.7005 249.181 51.7005C252.304 51.7005 254.56 50.3993 255.774 48.4908C256.425 47.4064 256.945 46.1052 257.032 44.5437H267.182C267.008 47.9269 265.881 51.3101 263.885 53.8692V53.7825Z"
        fill="currentColor"
      />
      <path
        d="M299.927 59.6371V33.1353C299.927 28.1906 298.279 25.1544 292.814 25.1544C286.611 25.1544 283.965 31.7473 283.965 37.039V59.6371H273.642V0.0839844H283.965V21.2941C286.438 17.6506 291.209 15.6988 295.676 15.6988C304.828 15.6988 310.207 22.2049 310.207 31.0533V59.6805H299.884L299.927 59.6371Z"
        fill="currentColor"
      />
      <path
        d="M362.039 53.7825C359.089 57.5127 353.884 60.5489 347.335 60.5489C340.785 60.5489 335.363 57.4259 332.414 53.7825C329.204 49.7486 327.729 44.3702 327.729 38.1243C327.729 31.8784 329.204 26.4999 332.414 22.4661C335.363 18.8227 340.829 15.6997 347.335 15.6997C353.841 15.6997 359.046 18.7359 362.039 22.4661C364.034 25.0252 365.162 28.4084 365.335 31.7916H355.186C355.099 30.2301 354.622 28.8855 353.928 27.8445C352.67 25.9361 350.458 24.6348 347.335 24.6348C344.515 24.6348 342.477 25.806 341.045 27.2807C338.573 30.0133 338.139 34.1338 338.139 38.1677C338.139 42.2015 338.573 46.2353 341.045 48.9679C342.433 50.4426 344.515 51.7005 347.335 51.7005C350.458 51.7005 352.713 50.3993 353.928 48.4908C354.578 47.4064 355.099 46.1052 355.186 44.5437H365.335C365.162 47.9269 364.034 51.3101 362.039 53.8692V53.7825Z"
        fill="currentColor"
      />
      <path
        d="M404.336 54.0427C401.387 57.6862 395.835 60.7224 389.329 60.7224C382.822 60.7224 377.27 57.6862 374.321 54.0427C371.111 50.0089 369.29 45.4546 369.29 38.211C369.29 30.9675 371.111 26.4132 374.321 22.3794C377.27 18.7359 382.822 15.6997 389.329 15.6997C395.835 15.6997 401.387 18.7359 404.336 22.3794C407.546 26.4132 409.368 30.9675 409.368 38.211C409.368 45.4546 407.546 50.0089 404.336 54.0427ZM395.835 27.4108C394.447 25.9361 392.105 24.6782 389.329 24.6782C386.553 24.6782 384.21 25.9361 382.822 27.4108C380.35 30.1434 379.786 34.1772 379.786 38.211C379.786 42.2449 380.35 46.2787 382.822 49.0113C384.21 50.486 386.553 51.7439 389.329 51.7439C392.105 51.7439 394.447 50.486 395.835 49.0113C398.307 46.2787 398.871 42.2449 398.871 38.211C398.871 34.1772 398.307 30.1434 395.835 27.4108Z"
        fill="currentColor"
      />
      <path
        d="M427.799 60.1603C420.772 60.1603 416.088 56.7771 416.088 48.6227V0H426.498V46.8444C426.498 49.3167 427.062 50.965 430.618 50.965C431.529 50.965 431.789 50.8782 432.353 50.8782V59.5531C430.271 59.9868 429.881 60.117 427.799 60.117V60.1603Z"
        fill="currentColor"
      />
      <path
        d="M449.266 60.1603C442.239 60.1603 437.555 56.7771 437.555 48.6227V0H447.965V46.8444C447.965 49.3167 448.528 50.965 452.085 50.965C452.996 50.965 453.256 50.8782 453.82 50.8782V59.5531C451.738 59.9868 451.348 60.117 449.266 60.117V60.1603Z"
        fill="currentColor"
      />
      <path
        d="M495.593 41.4235H466.879C466.793 44.72 467.964 47.8429 470.002 49.8382C471.477 51.2261 473.559 52.484 476.335 52.484C479.285 52.484 481.106 51.9201 482.624 50.402C483.535 49.4912 484.273 48.32 484.706 46.7586H494.596C494.335 49.3177 492.6 52.7009 490.952 54.6961C487.309 58.9902 481.974 60.7251 476.509 60.7251C470.653 60.7251 466.359 58.6432 462.976 55.4335C458.682 51.3129 456.383 45.2838 456.383 38.2572C456.383 31.2305 458.465 25.2882 462.585 21.0809C465.795 17.7844 470.436 15.6157 476.292 15.6157C482.624 15.6157 488.437 18.1748 491.95 23.5532C495.073 28.2377 495.81 33.139 495.68 38.474C495.68 38.474 495.593 40.4693 495.593 41.3801V41.4235ZM482.711 26.416C481.323 24.9412 479.154 23.9436 476.205 23.9436C473.082 23.9436 470.523 25.2448 469.048 27.2401C467.573 29.1485 466.923 31.1004 466.836 33.9197H485.4C485.314 30.71 484.403 28.2377 482.754 26.416H482.711Z"
        fill="currentColor"
      />
      <path
        d="M534.325 53.7825C531.376 57.5127 526.171 60.5489 519.621 60.5489C513.072 60.5489 507.65 57.4259 504.701 53.7825C501.491 49.7486 500.016 44.3702 500.016 38.1243C500.016 31.8784 501.491 26.4999 504.701 22.4661C507.65 18.8227 513.115 15.6997 519.621 15.6997C526.128 15.6997 531.332 18.7359 534.325 22.4661C536.321 25.0252 537.448 28.4084 537.622 31.7916H527.472C527.385 30.2301 526.908 28.8855 526.214 27.8445C524.956 25.9361 522.744 24.6348 519.621 24.6348C516.802 24.6348 514.763 25.806 513.332 27.2807C510.86 30.0133 510.426 34.1338 510.426 38.1677C510.426 42.2015 510.86 46.2353 513.332 48.9679C514.72 50.4426 516.802 51.7005 519.621 51.7005C522.744 51.7005 525 50.3993 526.214 48.4908C526.865 47.4064 527.385 46.1052 527.472 44.5437H537.622C537.448 47.9269 536.321 51.3101 534.325 53.8692V53.7825Z"
        fill="currentColor"
      />
      <path
        d="M560.874 60.247C552.199 60.247 547.905 55.3023 547.905 47.0612V25.2872H540.054V16.7858H547.905V5.85548L558.228 3.29639V16.7425H569.028V25.2439H558.228V46.107C558.228 49.4034 559.703 50.7914 562.999 50.7914C565.125 50.7914 566.86 50.7046 569.679 50.401V59.0759C566.773 59.8133 563.91 60.247 560.831 60.247H560.874Z"
        fill="currentColor"
      />
      <path
        d="M575.269 10.7996V0.172852H586.329V10.7996H575.269ZM575.573 59.6392V16.7419H586.069V59.6392H575.573Z"
        fill="currentColor"
      />
      <path
        d="M617.127 59.64H606.153L590.235 16.7427H601.122L611.835 46.9313L621.985 16.7427H632.612L617.084 59.64H617.127Z"
        fill="currentColor"
      />
      <path
        d="M671.86 41.4235H643.147C643.06 44.72 644.231 47.8429 646.269 49.8382C647.744 51.2261 649.826 52.484 652.602 52.484C655.552 52.484 657.373 51.9201 658.891 50.402C659.802 49.4912 660.54 48.32 660.973 46.7586H670.863C670.603 49.3177 668.868 52.7009 667.219 54.6961C663.576 58.9902 658.241 60.7251 652.776 60.7251C646.92 60.7251 642.626 58.6432 639.243 55.4335C634.949 51.3129 632.65 45.2838 632.65 38.2572C632.65 31.2305 634.732 25.2882 638.852 21.0809C642.062 17.7844 646.703 15.6157 652.559 15.6157C658.891 15.6157 664.704 18.1748 668.217 23.5532C671.34 28.2377 672.077 33.139 671.947 38.474C671.947 38.474 671.86 40.4693 671.86 41.3801V41.4235ZM659.022 26.416C657.634 24.9412 655.465 23.9436 652.515 23.9436C649.392 23.9436 646.833 25.2448 645.359 27.2401C643.884 29.1485 643.233 31.1004 643.147 33.9197H661.711C661.624 30.71 660.713 28.2377 659.065 26.416H659.022Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_908_1247">
        <rect width="672" height="60.7242" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
