// 1 -> 01
export const zeroPad = x => (x || '0').toString().padStart(1, '0')

export const formatDate = inputDate => {
    // Create a new Date object from the input string
    const completeInputDate = inputDate + ":00:00";

    // Create a new Date object from the complete input string
    const date = new Date(completeInputDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }
    // console.log(inputDate, date)
    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the Date object
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as "Mon DD, YYYY"
    return `${month} ${day}, ${year}`;
}


export const formatCTALabel = (label) => {
    return '.' + label.toLowerCase()
}

export const formatQuote = (quote) => {
    return '/* ' + quote + ' */'
}

export const formatSuffix = (label, suffix) => {
    return label + ' ' + suffix
}