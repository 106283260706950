<template>
  <component :is="elementType" :class="classString">
    <slot></slot>
  </component>
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) =>
      [
        "h1",
        "h2",
        "h3",
        "h4",
        "xxl",
        "error",
        "xl",
        "l",
        "m",
        "m-bold",
        "s",
        "s-bold",
        "xs",
        "body",
      ].includes(value),
  },
});

// Determine the element type dynamically based on the prop
let elementType = "p";

if (props.type.startsWith("h")) {
  elementType = props.type;
} else {
  elementType = "p";
}

// Define Tailwind classes dynamically
const classes = constants.fontClasses;

// Assign Tailwind classes based on the element type
const classString = classes[elementType === "p" ? props.type : elementType];
</script>
