<script setup></script>
<template>
  <span class="none absolute hidden h-0 w-0">TTC Colors</span>
</template>

<!-- 
bg-white
bg-white-text
bg-white-1
bg-white-2
bg-white-3
bg-cappuccino
bg-cappuccino-1
bg-cappuccino-2
bg-cappuccino-3
bg-cappuccino-text
bg-mustard
bg-mustard-1
bg-mustard-2
bg-mustard-3
bg-mustard-text
bg-plum
bg-plum-1
bg-plum-2
bg-plum-3
bg-plum-text
bg-mint
bg-mint-1
bg-mint-2
bg-mint-3
bg-mint-text
bg-grey
bg-grey-1
bg-grey-2
bg-grey-3
bg-grey-text
bg-purple
bg-purple-1
bg-purple-2
bg-purple-3
bg-purple-text
bg-dark-grey
bg-dark-grey-1
bg-dark-grey-2
bg-dark-grey-3
bg-dark-grey-text
bg-light-grey
bg-light-grey-1
bg-light-grey-2
bg-light-grey-3
bg-light-grey-text
text-white
text-white-1
text-white-text
text-cappuccino
text-cappuccino-1
text-cappuccino-2
text-cappuccino-3
text-cappuccino-text
text-mustard
text-mustard-1
text-mustard-2
text-mustard-3
text-mustard-text
text-plum
text-plum-1
text-plum-2
text-plum-3
text-plum-text
text-mint
text-mint-1
text-mint-2
text-mint-3
text-mint-text
text-grey
text-grey-1
text-grey-2
text-grey-3
text-grey-text
text-purple
text-purple-1
text-purple-2
text-purple-3
text-purple-text
text-dark-grey
text-dark-grey-1
text-dark-grey-2
text-dark-grey-3
text-dark-grey-text
text-light-grey
text-light-grey-1
text-light-grey-2
text-light-grey-3
text-light-grey-text -->
