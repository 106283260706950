<script setup>
const emit = defineEmits(["in-view"]);

const props = defineProps({
  threshold: { type: Number, default: 1 },
  rootMargin: { type: String, default: "0px" },
  once: Boolean,
  noop: Boolean,
});

const element = ref(null);

const isSeen = ref(false);
const inView = ref(false);

const options = {
  threshold: props.threshold,
  rootMargin: props.rootMargin,
  immediate: false,
};

const observer = ref(null);
onMounted(() => {
  if (props.noop) return;
  observer.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!props.once) {
        inView.value = entry.isIntersecting;
        emit("in-view", entry.isIntersecting);
      }

      if (!entry.isIntersecting) return;

      isSeen.value = true;
      emit("in-view", entry.isIntersecting);

      if (props.once) observer.value.unobserve(element.value);
    });
  }, options);

  if (!props.noop) {
    observer.value.observe(element.value);
  }
});

onBeforeUnmount(() => {
  if (!props.noop) {
    observer.value.unobserve(element.value);
  }
});
</script>

<template>
  <slot v-if="noop" />

  <div
    class="observer"
    ref="element"
    :class="{ 'is-seen': isSeen, 'in-view': inView }"
    v-else
  >
    <slot />
  </div>
</template>

<style>
.observer {
  height: 100%;
  width: 100%;
}
</style>
