// import gsap from 'gsap'
// import { Power1 } from 'gsap/all'
// import { CustomEase } from 'gsap/all'
// gsap.registerPlugin(CustomEase)
// list of random "hardcoded" values used throughout the frontend
// no mo magic numbers and strings
export default {
    PAGE_TRANSITION_DURATION: 600,

    fontClasses: {
        h1: "font-display text-xxl font-normal break-words whitespace-break-spaces",
        h2: "font-display text-l font-normal break-words",
        h3: "font-display text-m font-normal break-words",
        h4: "font-display text-s font-normal break-words whitespace-break-spaces",
        error: "font-display text-error font-normal break-words whitespace-break-spaces",
        xxl: "font-display text-xxl font-normal break-words whitespace-break-spaces",
        xl: "font-display text-xl font-normal break-words whitespace-break-spaces",
        l: "font-display text-l font-normal break-words whitespace-break-spaces",
        m: "font-display text-m font-normal break-words whitespace-break-spaces",
        "m-bold": "font-display text-m font-bold break-words whitespace-break-spaces",
        s: "font-display text-s font-normal break-words whitespace-break-spaces",
        "s-bold": "font-display text-s font-bold break-words whitespace-break-spaces",
        xs: "font-display text-xs font-normal break-words whitespace-break-spaces",
        body: "font-text text-body font-normal break-words whitespace-break-spaces",
    }

    // ANIMATIONS: {
    //     DURATION: 200,
    //     STAGGER_EASE: CustomEase.create("custom", "0.83, 0, 0.17, 1"),
    //     STAGGER: 0.3,
    //     STAGGER_FROM: "random",
    //     STAGGER_DURATION: 0.3,
    //     TICKER_EASE: Power1.easeInOut,
    //     TICKER_MIN_DURATION: 3,
    // },
}