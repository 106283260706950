import { TextSplitter } from './text-splitter.js';
import { gsap } from 'gsap';

const lettersAndSymbols = ['!', '@', '#', '$', '%', '^', '&', '*', '-', '_', '+', '=', ';', ':', '<', '>', ','];

export class TextAnimator {
    constructor(textElement) {
        if (!textElement || !(textElement instanceof HTMLElement)) {
            throw new Error('Invalid text element provided.');
        }

        this.textElement = textElement;
        this.originalChars = [];
        this.splitText();
    }

    splitText() {
        this.splitter = new TextSplitter(this.textElement, {
            splitTypeTypes: 'words, chars'
        });

        this.originalChars = this.splitter.getChars().map(char => char.innerHTML);
    }

    // Make this function asynchronous
    async animate() {
        this.reset();

        // Return a promise that resolves when the animation is complete
        return new Promise((resolve) => {
            const chars = this.splitter.getChars();
            let animationsComplete = 0;

            chars.forEach((char, position) => {
                let initialHTML = char.innerHTML;

                gsap.fromTo(char, {
                    opacity: 0
                },
                    {
                        duration: 0.03,
                        onComplete: () => {
                            gsap.set(char, { innerHTML: initialHTML, delay: 0.1 });
                            animationsComplete++;
                            if (animationsComplete === chars.length) {
                                resolve();  // Resolve the promise when all animations are done
                            }
                        },
                        repeat: 2,
                        repeatRefresh: true,
                        repeatDelay: 0.05,
                        delay: (position + 1) * 0.06,
                        innerHTML: () => lettersAndSymbols[Math.floor(Math.random() * lettersAndSymbols.length)],
                        opacity: 1
                    });
            });

            gsap.fromTo(this.textElement, {
                '--anim': 0
            },
                {
                    duration: 1,
                    ease: 'expo',
                    '--anim': 1,
                    onComplete: () => {
                        // If the text animation finishes before all chars animations
                        if (animationsComplete === chars.length) {
                            resolve();  // Resolve only if all characters finished animating
                        }
                    }
                });
        });
    }

    animateBack() {
        gsap.killTweensOf(this.textElement);
        gsap.to(this.textElement, {
            duration: .6,
            ease: 'power4',
            '--anim': 0
        });
    }

    reset() {
        const chars = this.splitter.getChars();
        chars.forEach((char, index) => {
            gsap.killTweensOf(char);
            char.innerHTML = this.originalChars[index];
        });

        gsap.killTweensOf(this.textElement);
        gsap.set(this.textElement, { '--anim': 0 });
    }
}
