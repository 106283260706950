<script setup>
const { breadcrumbs } = useTTCState();

// Truncate function to limit to 35 characters
const truncate = (text, length = 35) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};
</script>

<template>
  <ul v-if="breadcrumbs" class="flex">
    <li
      v-for="crumb in breadcrumbs"
      :key="crumb.url"
      class="mr-0.5 whitespace-nowrap text-xs font-bold leading-none"
    >
      <nuxt-link :to="crumb.url">
        \{{ truncate(crumb.title.toLowerCase()) }}
      </nuxt-link>
    </li>
  </ul>
</template>
