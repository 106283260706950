const useGlobalData = () => useState('global-data', () => null)
const useBreadcrumbs = () =>
  useState('global-breadcrumbs', () => null)

export default () => {
  const globalData = useGlobalData()
  const breadcrumbs = useBreadcrumbs()

  function setGlobalData(data) {
    if (data) {
      globalData.value = data
    }
  }

  return {
    globalData,
    setGlobalData,
    breadcrumbs
  }
}
