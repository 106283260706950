<script setup>
const { globalData } = useTTCState();
const data = ref(globalData.value.footer);
const menu = ref(globalData.value.mainMenu);

const route = useRoute();
const currentTheme = ref(null);

onMounted(() => {
  const { getCurrentTheme, isBlackText } = useTheme();
  currentTheme.value = getCurrentTheme();
});
</script>

<template>
  <footer
    class="footer mx-bleed my-bleed"
    ref="footer"
    data-section-theme="dark"
  >
    <div
      :class="[
        `grid gap-4 p-4 transition-colors duration-300 md:grid-cols-2 bg-${currentTheme}-2`,
        {
          'text-[black]': currentTheme !== 'dark-grey',
          'text-[white]': currentTheme === 'dark-grey',
        },
      ]"
    >
      <ul class="grid grid-cols-1 gap-6 text-xs md:gap-4">
        <li
          v-for="item in menu.data"
          :key="item.id"
          :class="item.level == 1 ? 'text-s font-bold' : '-mt-2 md:-mt-1'"
        >
          <nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
        </li>
      </ul>
      <div class="flex flex-col justify-between">
        <div class="grid items-start justify-start md:grid-cols-2">
          <div class="mt-10 grid grid-cols-1 gap-3 md:mt-0">
            <span class="text-s font-bold">{{ data.contact.heading }}</span>
            <ul
              class="grid grid-cols-1 gap-3 text-xs"
              v-for="address in data.contact.addresses"
              :key="address.id"
            >
              <li>
                <a :href="data.contact.emailLink">{{
                  data.contact.emailLabel
                }}</a>
              </li>
              <li>
                <a
                  :href="data.contact.linkedInUrl.url"
                  :target="data.contact.linkedInUrl.target"
                  >LinkedIn</a
                >
              </li>
              <li>
                <a :href="data.contact.phoneNumberLink">{{
                  data.contact.phoneNumberLabel
                }}</a>
              </li>
              <li>
                {{ address.addressLine1 }}, {{ address.postalCode }}
                {{ address.locality }}
              </li>
            </ul>
          </div>

          <div class="mt-10 grid grid-cols-1 gap-3 md:mt-0">
            <span class="text-s font-bold">{{ data.legal.heading }}</span>
            <ul class="grid grid-cols-1 gap-3 text-xs">
              <li v-for="(item, idx) in data.legal.links" :key="item.id">
                <nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
              </li>
            </ul>
            <span class="text-xs"
              >@ {{ new Date().getFullYear() }} {{ data.legal.copyright }}</span
            >
          </div>
        </div>
        <div class="mt-20 flex flex-col items-end justify-end">
          <nuxt-link
            to="/"
            class="w-full"
            aria-label="The Tech Collective logo"
          >
            <globals-logo />
          </nuxt-link>
          <span class="mt-4 text-xs">Powered by Implement</span>
        </div>
      </div>
    </div>
  </footer>
</template>
