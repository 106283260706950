/**
 * Define data theme used in the frontend
 */
const setGlobalTheme = theme => {
    if (!process.client) return;

    // Set the data-theme attribute
    document.body.setAttribute("data-theme", theme ? theme : "light-grey");

    // Set the --current-theme variable based on the theme
    const cssVariable = getCorrespondingCSS(theme);
    document.body.style.setProperty('--current-theme', `var(${cssVariable})`);
}

const getCurrentTheme = () => {
    if (!process.client) return;
    return document.body.getAttribute("data-theme");
}

const getCorrespondingCSS = theme => {
    const themeMap = {
        'white': '--white',
        'cappuccino': '--cappuccino',
        'mustard': '--mustard',
        'plum': '--plum',
        'mint': '--mint',
        'grey': '--grey',
        'purple': '--purple',
        'dark-grey': '--dark-grey',
        'light-grey': '--light-grey',
    };

    return themeMap[theme] || '--white'; // Default to --white if the theme is not found
}

const isBlackText = (theme) => {
    if (!theme) return false;
    return (
        theme === "mustard" ||
        theme === "white" ||
        theme === "grey" ||
        theme === "light-grey" ||
        theme === "mint" ||
        theme === "purple" ||
        theme === "cappuccino"
    );
}

const getRandomTheme = (themes = ['white', 'cappuccino', 'mustard', 'plum', 'mint', 'grey', 'purple', 'dark-grey', 'light-grey']) => {
    return themes[Math.floor(Math.random() * themes.length)];
}

export default
    () => {
        return {
            setGlobalTheme,
            getCurrentTheme,
            getCorrespondingCSS,
            getRandomTheme,
            isBlackText
        }
    }
