<script setup>
const props = defineProps({
  ariaLabel: String,
  isBig: {
    type: Boolean,
    default: false,
  },
  button: {
    type: Boolean,
    default: false,
  },
  buttonLabel: String,
  cta: Object,
  hasBackground: {
    type: Boolean,
    default: true,
  },
  invertBackground: {
    type: Boolean,
    default: false,
  },
  hasHoverEffect: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["click"]);
const callToAction = ref(null);
const btn = ref(null);
onMounted(() => {
  if (callToAction.value && props.hasHoverEffect) {
    const animator = new TextAnimator(callToAction.value.$el);
    callToAction.value.$el.addEventListener("mouseenter", () => {
      animator.animate();
    });
    callToAction.value.$el.addEventListener("mouseleave", () => {
      animator.animateBack();
    });
  }
  if (props.button && props.hasHoverEffect) {
    const text = btn.value.querySelector("p");
    if (!text) return;
    const animator = new TextAnimator(text);
    btn.value.addEventListener("mouseenter", () => {
      animator.animate();
    });
    btn.value.addEventListener("mouseleave", () => {
      animator.animateBack();
    });
  }
});

onUnmounted(() => {
  if (callToAction.value && props.hasHoverEffect) {
    callToAction.value.$el.removeEventListener("mouseenter", () => {});
    callToAction.value.$el.removeEventListener("mouseleave", () => {});
  }
  if (props.button && props.hasHoverEffect) {
    btn.value?.removeEventListener("mouseenter", () => {});
    btn.value?.removeEventListener("mouseleave", () => {});
  }
});

const currentTheme = ref(null);
onMounted(() => {
  const { getCurrentTheme } = useTheme();
  currentTheme.value = getCurrentTheme();
});
</script>

<template>
  <nuxt-link
    :aria-label="cta.label"
    class="cta block w-max cursor-pointer"
    :class="{
      'bg-white text-dark-grey': hasBackground && !invertBackground,
      'bg-dark-grey text-white': hasBackground && invertBackground,
      'px-8 py-2': isBig,
      'px-[4px] py-[2px]': !isBig,
    }"
    v-if="cta && !button"
    :to="cta.href"
    :target="cta.target"
    ref="callToAction"
  >
    <ui-font-text type="s-bold" v-if="cta.label" class="pointer-events-none">
      {{ formatCTALabel(cta.label) }}
    </ui-font-text>
  </nuxt-link>
  <button
    @click="(e) => emit('click', e)"
    ref="btn"
    :aria-label="ariaLabel || 'button'"
    class="cta block w-max cursor-pointer"
    :class="{
      'bg-white text-dark-grey': hasBackground && !invertBackground,
      'bg-dark-grey text-white': hasBackground && invertBackground,
      'px-8 py-2': isBig,
      'px-[4px] py-[2px]': !isBig,
    }"
    v-if="button"
  >
    <slot />
  </button>
</template>
