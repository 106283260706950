<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useToggle } from "@vueuse/core";
import { useRoute } from "vue-router";
gsap.registerPlugin(ScrollTrigger);
const { globalData } = useTTCState();
const [isOpen, toggle] = useToggle(false);
const route = useRoute();
const menu = ref(globalData.value.mainMenu);
const buttonLabel = ref(".menu");

watch(route, () => {
  toggle(false);
  cleanScrollTriggers();
});

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();
const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});

const isHeaderFixed = ref(true);

const headerRef = ref(null);

const btnLabel = ref(null);

const handleMenu = async () => {
  if (btnLabel.value) {
    const animator = new TextAnimator(btnLabel.value);
    toggle();
    await animator.animate();
    buttonLabel.value = isOpen.value ? ".close" : ".menu";
  }
};
let scrollTriggers = [];
const initScrollTriggers = () => {
  cleanScrollTriggers();
  if (!headerRef.value) return;
  const headerHeight = headerRef.value.getBoundingClientRect().height;
  document.body.style.setProperty("--header-height", `${headerHeight}px`);
  if (isHeaderFixed.value) {
    const layout = document.querySelector(".layout");
    if (layout) layout.classList.add("-mt-[var(--header-height)]");
  }
  const sections = document.querySelectorAll("[data-section-theme]");
  if (!sections) return;

  sections.forEach((section) => {
    const dataTheme = section.getAttribute("data-section-theme");
    if (dataTheme !== "dark" || dataTheme === "plum") return;
    const trigger = ScrollTrigger.create({
      trigger: section,
      start: `top top+=${isMobile.value ? "30px" : "1px"}`,
      end: `bottom top+=${isMobile.value ? "30px" : "1px"}`,
      scrub: 1,
      // markers: true,
      onEnter: () => {
        headerRef.value.style.setProperty("--nav-color", "black");
      },
      onEnterBack: () => {
        headerRef.value.style.setProperty("--nav-color", "black");
      },
      onLeave: () => {
        headerRef.value.style.setProperty("--nav-color", "white");
      },
      onLeaveBack: () => {
        headerRef.value.style.setProperty("--nav-color", "white");
      },
    });

    scrollTriggers.push(trigger);
  });

  //Centering nav on load then scroll on Desktop only
  if (isMobile.value || route.name !== "index") return;
  const hero = document.querySelector(".hero");
  isHeaderFixed.value = false;
  const trigger = ScrollTrigger.create({
    trigger: hero,
    start: `center-=${headerRef.value.offsetHeight + headerRef.value.getBoundingClientRect().height / 2} top`,
    end: `center+=${headerRef.value.offsetHeight} bottom`,
    // markers: true,
    onEnter: () => {
      isHeaderFixed.value = false;
    },
    onLeave: () => {
      isHeaderFixed.value = true;
    },
    onEnterBack: () => {
      isHeaderFixed.value = true;
    },
    onLeaveBack: () => {
      isHeaderFixed.value = true;
    },
    scrub: true,
  });

  scrollTriggers.push(trigger);
};

const cleanScrollTriggers = () => {
  // if (!scrollTriggers || !scrollTriggers.length < 1) return;
  scrollTriggers.forEach((trigger) => trigger.kill());
  scrollTriggers = [];
  ScrollTrigger.refresh();
};
onMounted(() => {
  // setTimeout(() => {
  initScrollTriggers();
  // });
  let initialWidth = window.innerWidth;

  window.addEventListener("resize", function () {
    const currentWidth = window.innerWidth;

    if (currentWidth !== initialWidth) {
      // Refresh ScrollTrigger only if the resize happens on the X-axis
      // ScrollTrigger.refresh();
      cleanScrollTriggers();
      initScrollTriggers();

      initialWidth = currentWidth; // Update the initial width for future comparisons
      // console.log("resize X", ScrollTrigger.getAll());
    }

    // Update the header height and other styles
    const headerHeight = headerRef.value?.getBoundingClientRect().height;
    document.body.style.setProperty("--header-height", `${headerHeight}px`);

    if (isHeaderFixed.value) {
      const layout = document.querySelector(".layout");
      if (layout) layout.classList.add("-mt-[var(--header-height)]");
    }
  });
});

onUnmounted(() => {
  cleanScrollTriggers();
});

watch(isHeaderFixed, () => {
  if (isHeaderFixed.value) {
    const layout = document.querySelector(".layout");
    if (layout) layout.classList.add("-mt-[var(--header-height)]");
  } else {
    const layout = document.querySelector(".layout");
    if (layout) layout.classList.remove("-mt-[var(--header-height)]");
  }
});
</script>

<template>
  <header
    :class="[
      'navbar z-50 w-full py-5',
      isHeaderFixed || isMobile
        ? 'fixed-header sticky top-0'
        : 'transition-500 absolute-header absolute top-1/2 -translate-y-1/2',
    ]"
    ref="headerRef"
  >
    <!-- :style="`--nav-color: ${isIntersecting ? 'black' : 'white'};`" -->
    <div
      v-if="isOpen"
      class="menu-background fixed z-[-1] h-screen w-screen cursor-pointer"
      @click="handleMenu()"
    ></div>
    <div class="grid grid-cols-2 items-end gap-4">
      <div class="relative flex items-end pl-bleed">
        <button @click="handleMenu()">
          <span
            ref="btnLabel"
            class="relative z-10 text-menu font-semibold leading-none"
            :class="{
              'text-white': isOpen,
              'text-[var(--nav-color)]': !isOpen,
            }"
            >{{ buttonLabel }}</span
          >
        </button>

        <component-element-breadcrumbs
          class="absolute -bottom-5 left-bleed z-10 opacity-50 md:bottom-1.5 md:left-24"
          :class="{ 'text-white': isOpen, 'text-[var(--nav-color)]': !isOpen }"
        />

        <transition name="expand">
          <div
            v-if="isOpen"
            class="absolute -top-5 left-0 flex w-screen origin-top-left items-center md:w-auto"
          >
            <span
              class="absolute flex h-full w-full bg-dark-grey/50 backdrop-blur-xl"
            ></span>
            <nav class="relative px-bleed pb-10 pt-28 md:px-24 md:pt-20">
              <ul class="grid grid-cols-1 gap-6 text-s md:gap-4 md:text-xs">
                <li
                  v-for="item in menu.data"
                  :key="item.id"
                  :class="item.level == 1 ? 'font-bold' : '-mt-2 md:-mt-1'"
                >
                  <nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
                </li>
              </ul>
            </nav>
            <ui-tag-corners />
          </div>
        </transition>
      </div>

      <div class="flex justify-end pb-1.5 pr-bleed md:justify-start">
        <nuxt-link
          to="/"
          class="h-3 w-auto text-[var(--nav-color)]"
          :class="{
            'pointer-events-all cursor-pointer': route.name !== 'index',
            'pointer-events-none cursor-default': route.name === 'index',
          }"
          aria-label="The Tech Collective logo"
        >
          <globals-logo class="w-auto" />
        </nuxt-link>
      </div>
    </div>
    <div
      class="gradient-blur"
      :style="`--nav-blur: ${isHeaderFixed ? 12 : 0}px;`"
    ></div>
  </header>
</template>

<style scoped>
.navbar {
}

.gradient-blur {
  --nav-blur: 0px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  /* inset: auto 0 0 0; */
  height: 100%;
  pointer-events: none;
}
.gradient-blur::before,
.gradient-blur::after {
  position: absolute;
  inset: 0;
}
.gradient-blur::before {
  transition: backdrop-filter 0.3s var(--cubic-ease);
  content: "";
  z-index: -1;
  backdrop-filter: blur(var(--nav-blur));
  mask: linear-gradient(
    to bottom,
    rgba(31, 31, 35, 1) 50%,
    rgba(31, 31, 35, 1) 60%,
    rgba(31, 31, 35, 0) 100%
  );
}

.absolute-header {
  transition: all 0.5s var(--cubic-ease);
}
.expand-enter-active {
  transition: all 0.4s var(--cubic-ease);
}

.expand-leave-active {
  transition: all 0.4s var(--cubic-ease) 0.1s;
}

.expand-leave-to,
.expand-enter-from {
  transform: scale(0);
}

.expand-enter-active nav {
  transition: all 0.1s var(--cubic-ease) 0.3s;
}

.expand-leave-active nav {
  transition: all 0.1s var(--cubic-ease) 0s;
}

.expand-enter-from nav,
.expand-leave-to nav {
  opacity: 0;
}
</style>
